.error-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.error-page .title {
  margin: 50px 0 10px;
  text-transform: uppercase;
  font-size: 1.8em;
  font-family: 'GalanoBold';
}
.error-page .message,
.error-page .support-link-wrapper {
  text-align: center;
}
.error-page .message {
  margin-top: 20px;
}
.error-page .art {
  width: 100%;
  height: 100%;
  background-image: url('assets/UnderMaintenance.svg');
  background-repeat: no-repeat;
  background-position: center -10px;
  background-size: contain;
  display: flex;
  flex: 1;
}

/* body {
  overflow-y: auto !important;
} */