.app-header {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #FFF;
}
.app-header.w-boarder {
  border-bottom-color: #CCC;
  user-select: none;
}

.app-logo {
  transform: translateY(-20px);
}
.custom-talon-logo {
  height: auto;
  width: 120px;
  margin-bottom: 20px;
}
.log-out {
  font-family: 'Galano';
  font-size: 1.1em;
  color: #999B9D;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 15px;
  user-select: none;
  z-index: 2;
}
.app-header .svg-line {
  fill: #222325;
}
.app-header .svg-line {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
.app-header .svg-text {
  font-family:  'GalanoBold', 'Galano';
  font-size: 27px;
}
.app-header .svg-span-1 {
  letter-spacing: .26em;
}
.app-header .svg-span-2 {
  letter-spacing: .23em;
}
.app-header .svg-span-3 {
  letter-spacing: .26em;
}
