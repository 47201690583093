@font-face {
  font-family: 'Galano';
  src: local('Galano'), url('./fonts/Galano-Grotesque.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'GalanoBold';
  src: local('GalanoBold'), url('./fonts/Galano-Grotesque-Bold.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Webdings';
  src: local('Webdings'),
  url('./fonts/Webdings.ttf') format('truetype');
  font-display: swap;
}

html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Galano', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  color: #2F2F2F
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

strong {
  font-family: 'GalanoBold';
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #EEE;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #DDD;
  border-radius: 15px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #CCC;
}

:root {
  --box-shadow-small:1px 1px 6px -2px rgb(0 0 0 / 50%);
  --box-shadow:2px 2px 8px 2px rgb(0 0 0 / 20%);
  --lightGrey1:#F6F6F6;
  --lightGrey2:#F2F2F2;
  --grey1:#999B9D;
  --darkGrey:#2F2F2F;
  --almostWhite:#F2F2F2;
  --main-blue:#3691AB;
  --text-shadow-border-white:-1px -1px 0 white,
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
}
