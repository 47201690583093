.cs-action {
  color: #87878f;
  font-size: 0.7em;
  margin-top: 15px;
  cursor: pointer;
}
.cs-link {
  text-decoration: none;
}
.cs-action, .cs-link {
  user-select: none;
}
.mantine-Popover-dropdown .cs-link-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
